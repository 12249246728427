import React, { useRef } from 'react'
import clsx from 'clsx'

import { PageProps } from 'gatsby'
import { Layout } from '../components/layout'
import { FiExternalLink } from 'react-icons/fi'

import * as styles from '../styles/pages/index.module.scss'
import mainHeader from '../images/mainHeader3.png'
import blog from '../images/blog.png'

import { SEO } from '../components/seo'
import { Menu } from '../components/menu'
import { Footer } from '../components/footer'
import { solutionsItems } from '../utils'
import { SliderCases } from '../components/sliderCases'
import { Contact } from '../components/contact/contact'
import { SliderCustomers } from '../components/sliderCustomers/sliderCustomers'
import { ourCustomersList } from '../utils/customersList'

const IndexPage: React.FC<PageProps> = ({ location }) => {
  const blogRef = useRef<HTMLDivElement>(null)

  return (
    <Layout location={location}>
      <SEO title="High Jump - Process consulting " />
      <div className={styles.home}>
        <Menu />
        <main>
          <section className={styles.intro}>
            <img src={mainHeader} alt="High jump Consulting" />
            <div className={styles.mainTitle}>
              <h1>
                Maximize o resultado <br />
                do seu negócio
              </h1>
              <p>
                Somos uma consultoria especializada em melhoria de processos.
                Ajudamos empresas fornecendo soluções para seus desafios de
                forma ágil, prática e inovadora, por meio de boas práticas de
                mercado em Processos, Qualidade, Compliance e Tecnologia.
              </p>
            </div>
          </section>

          <div className={styles.container}>
            <section className={styles.solutions} id="solutions">
              <div className="container">
                <div className={styles.title}>
                  <h1>Soluções</h1>
                </div>
                {solutionsItems.map((item, index) => (
                  <div
                    className={clsx('row', styles.solution, {
                      [styles.invert]: index % 2 !== 0,
                    })}
                    key={index}
                  >
                    <div className="col-12 col-lg-4">
                      <div className={styles.solutionImg}>{item.image}</div>
                    </div>
                    <div className="col-12 col-lg-8">
                      <div className={styles.solutionContent}>
                        <div className={styles.solutionTitle}>
                          <div>
                            <h2>{item.title}</h2>
                            <p>{item.subtitle}</p>
                          </div>
                        </div>
                        <div className={styles.solutionDescription}>
                          <p>{item.description}</p>
                          {item.link && item.link}
                        </div>
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </section>
          </div>

          <section className={styles.cases} id="cases">
            <div className={styles.title}>
              <h1>Cases de Sucesso</h1>
              <span></span>
              <h3>Grandes saltos e resultados</h3>
            </div>
            <SliderCases />
            <SliderCustomers customers={ourCustomersList} />
          </section>

          <section className={styles.blog} ref={blogRef}>
            <div className={styles.title}>
              <h1>Blog</h1>
              <a href="https://blog.highjump.com.br" target="_blank">
                Ir para o blog <FiExternalLink />
              </a>
            </div>
            <div className={styles.imageContent}>
              <img src={blog} alt="blog" />
            </div>
          </section>

          <Contact locationPath={'Home page'} />

          <Footer />
        </main>
      </div>
    </Layout>
  )
}

export default IndexPage
