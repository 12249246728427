import React, { useRef, useState } from 'react'
import clsx from 'clsx'

import Slider from 'react-slick'
import 'slick-carousel/slick/slick.css'
import 'slick-carousel/slick/slick-theme.css'

import * as styles from './styles.module.scss'
import { FiChevronLeft, FiChevronRight } from 'react-icons/fi'
import { FaQuoteRight } from 'react-icons/fa'

import sliderHeader from '../../images/cases/sliderHeader.png'
import { testimonialsList } from '../../utils/customersList'

export const SliderCases: React.FC = () => {
  const sliderRef = useRef<Slider>(null)
  const [currentSlide, setCurrentSlide] = useState({
    slideIndex: 1,
  })

  return (
    <div className={styles.sliderContent}>
      <div className={styles.arrows}>
        <span
          onClick={() => {
            sliderRef.current?.slickPrev()
          }}
          className={currentSlide.slideIndex === 1 ? styles.disabled : ''}
        >
          <FiChevronLeft />
        </span>
        <div className={styles.count}>
          {currentSlide.slideIndex}/{testimonialsList.length}
        </div>
        <span
          onClick={() => {
            sliderRef.current?.slickNext()
          }}
          className={
            currentSlide.slideIndex === testimonialsList.length
              ? styles.disabled
              : ''
          }
        >
          <FiChevronRight />
        </span>
      </div>

      <div className={styles.sliderImage}>
        <img
          src={sliderHeader}
          alt="Cases de sucesso"
          title="Cases de sucesso"
        />
      </div>

      <Slider
        ref={sliderRef}
        className={styles.listClients}
        adaptiveHeight={false}
        infinite={true}
        autoplay={false}
        slidesToScroll={1}
        dots={false}
        draggable={true}
        speed={300}
        arrows={false}
        variableWidth={true}
        beforeChange={(current, next) =>
          setCurrentSlide((prevState) => ({
            ...prevState,
            slideIndex: next + 1,
          }))
        }
        responsive={[
          {
            breakpoint: 1024,
            settings: {
              slidesToShow: 2,
              slidesToScroll: 2,
              dots: true,
              arrows: false,
            },
          },
          {
            breakpoint: 600,
            settings: {
              slidesToShow: 1,
              slidesToScroll: 1,
              dots: false,
              arrows: false,
              adaptiveHeight: true,
              variableWidth: false,
            },
          },
        ]}
      >
        {testimonialsList.map((testimonial, index) => (
          <div className={styles.client} key={index}>
            <div className={clsx(styles.box, { [styles.line]: index === 0 })}>
              <p>
                <i>" </i>
                {testimonial.text}
                <i>"</i>
              </p>
              <strong>{testimonial.name}</strong>
              <small>{testimonial.role}</small>
              <FaQuoteRight className={styles.quoteIcon} />
            </div>
          </div>
        ))}
      </Slider>
    </div>
  )
}
